/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreatePublicGameTemplateInput = {
  id?: string | null,
  title: string,
  owner: string,
  version: number,
  description: string,
  ccss?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  gradeFilter?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplatesCount: number,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelPublicGameTemplateConditionInput = {
  title?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  version?: ModelIntInput | null,
  description?: ModelStringInput | null,
  ccss?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  gradeFilter?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  imageUrl?: ModelStringInput | null,
  questionTemplatesCount?: ModelIntInput | null,
  questionTemplatesOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPublicGameTemplateConditionInput | null > | null,
  or?: Array< ModelPublicGameTemplateConditionInput | null > | null,
  not?: ModelPublicGameTemplateConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type PublicGameTemplate = {
  __typename: "PublicGameTemplate",
  id: string,
  title: string,
  owner: string,
  version: number,
  description: string,
  ccss?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  gradeFilter?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplates?: ModelPublicGameQuestionsConnection | null,
  questionTemplatesCount: number,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelPublicGameQuestionsConnection = {
  __typename: "ModelPublicGameQuestionsConnection",
  items:  Array<PublicGameQuestions | null >,
  nextToken?: string | null,
};

export type PublicGameQuestions = {
  __typename: "PublicGameQuestions",
  id: string,
  publicGameTemplateID: string,
  publicQuestionTemplateID: string,
  publicGameTemplate: PublicGameTemplate,
  publicQuestionTemplate: PublicQuestionTemplate,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type PublicQuestionTemplate = {
  __typename: "PublicQuestionTemplate",
  id: string,
  owner: string,
  title: string,
  version: number,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplates?: ModelPublicGameQuestionsConnection | null,
  gameTemplatesCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type UpdatePublicGameTemplateInput = {
  id: string,
  title?: string | null,
  owner?: string | null,
  version?: number | null,
  description?: string | null,
  ccss?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  gradeFilter?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplatesCount?: number | null,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type DeletePublicGameTemplateInput = {
  id: string,
};

export type CreatePrivateGameTemplateInput = {
  id?: string | null,
  owner: string,
  title: string,
  version: number,
  description: string,
  ccss?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  gradeFilter?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplatesCount: number,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelPrivateGameTemplateConditionInput = {
  owner?: ModelStringInput | null,
  title?: ModelStringInput | null,
  version?: ModelIntInput | null,
  description?: ModelStringInput | null,
  ccss?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  gradeFilter?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  imageUrl?: ModelStringInput | null,
  questionTemplatesCount?: ModelIntInput | null,
  questionTemplatesOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPrivateGameTemplateConditionInput | null > | null,
  or?: Array< ModelPrivateGameTemplateConditionInput | null > | null,
  not?: ModelPrivateGameTemplateConditionInput | null,
};

export type PrivateGameTemplate = {
  __typename: "PrivateGameTemplate",
  id: string,
  owner: string,
  title: string,
  version: number,
  description: string,
  ccss?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  gradeFilter?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplates?: ModelPrivateGameQuestionsConnection | null,
  questionTemplatesCount: number,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelPrivateGameQuestionsConnection = {
  __typename: "ModelPrivateGameQuestionsConnection",
  items:  Array<PrivateGameQuestions | null >,
  nextToken?: string | null,
};

export type PrivateGameQuestions = {
  __typename: "PrivateGameQuestions",
  id: string,
  privateGameTemplateID: string,
  privateQuestionTemplateID: string,
  privateGameTemplate: PrivateGameTemplate,
  privateQuestionTemplate: PrivateQuestionTemplate,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type PrivateQuestionTemplate = {
  __typename: "PrivateQuestionTemplate",
  id: string,
  owner: string,
  title: string,
  version: number,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplates?: ModelPrivateGameQuestionsConnection | null,
  gameTemplatesCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type UpdatePrivateGameTemplateInput = {
  id: string,
  owner?: string | null,
  title?: string | null,
  version?: number | null,
  description?: string | null,
  ccss?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  gradeFilter?: string | null,
  standard?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  imageUrl?: string | null,
  questionTemplatesCount?: number | null,
  questionTemplatesOrder?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type DeletePrivateGameTemplateInput = {
  id: string,
};

export type CreatePublicQuestionTemplateInput = {
  id?: string | null,
  owner: string,
  title: string,
  version: number,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplatesCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelPublicQuestionTemplateConditionInput = {
  owner?: ModelStringInput | null,
  title?: ModelStringInput | null,
  version?: ModelIntInput | null,
  choices?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  gameTemplatesCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPublicQuestionTemplateConditionInput | null > | null,
  or?: Array< ModelPublicQuestionTemplateConditionInput | null > | null,
  not?: ModelPublicQuestionTemplateConditionInput | null,
};

export type UpdatePublicQuestionTemplateInput = {
  id: string,
  owner?: string | null,
  title?: string | null,
  version?: number | null,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplatesCount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type DeletePublicQuestionTemplateInput = {
  id: string,
};

export type CreatePrivateQuestionTemplateInput = {
  id?: string | null,
  owner: string,
  title: string,
  version: number,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplatesCount: number,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type ModelPrivateQuestionTemplateConditionInput = {
  owner?: ModelStringInput | null,
  title?: ModelStringInput | null,
  version?: ModelIntInput | null,
  choices?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  gameTemplatesCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPrivateQuestionTemplateConditionInput | null > | null,
  or?: Array< ModelPrivateQuestionTemplateConditionInput | null > | null,
  not?: ModelPrivateQuestionTemplateConditionInput | null,
};

export type UpdatePrivateQuestionTemplateInput = {
  id: string,
  owner?: string | null,
  title?: string | null,
  version?: number | null,
  choices?: string | null,
  instructions?: string | null,
  answerSettings?: string | null,
  domain?: string | null,
  cluster?: string | null,
  grade?: string | null,
  standard?: string | null,
  imageUrl?: string | null,
  gameTemplatesCount?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  type?: string | null,
};

export type DeletePrivateQuestionTemplateInput = {
  id: string,
};

export type CreateGameSessionInput = {
  id?: string | null,
  gameId: string,
  startTime?: string | null,
  phaseOneTime: number,
  phaseTwoTime: number,
  currentQuestionIndex?: number | null,
  currentState: GameSessionState,
  gameCode: number,
  isAdvancedMode: boolean,
  imageUrl?: string | null,
  description?: string | null,
  title?: string | null,
  currentTimer?: number | null,
  sessionData?: string | null,
};

export enum GameSessionState {
  NOT_STARTED = "NOT_STARTED",
  TEAMS_JOINING = "TEAMS_JOINING",
  CHOOSE_CORRECT_ANSWER = "CHOOSE_CORRECT_ANSWER",
  PHASE_1_DISCUSS = "PHASE_1_DISCUSS",
  PHASE_2_START = "PHASE_2_START",
  CHOOSE_TRICKIEST_ANSWER = "CHOOSE_TRICKIEST_ANSWER",
  PHASE_2_DISCUSS = "PHASE_2_DISCUSS",
  FINAL_RESULTS = "FINAL_RESULTS",
  FINISHED = "FINISHED",
}


export type ModelGameSessionConditionInput = {
  gameId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  currentState?: ModelGameSessionStateInput | null,
  gameCode?: ModelIntInput | null,
  isAdvancedMode?: ModelBooleanInput | null,
  imageUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  title?: ModelStringInput | null,
  currentTimer?: ModelIntInput | null,
  sessionData?: ModelStringInput | null,
  and?: Array< ModelGameSessionConditionInput | null > | null,
  or?: Array< ModelGameSessionConditionInput | null > | null,
  not?: ModelGameSessionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelGameSessionStateInput = {
  eq?: GameSessionState | null,
  ne?: GameSessionState | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type GameSession = {
  __typename: "GameSession",
  id: string,
  gameId: string,
  startTime?: string | null,
  phaseOneTime: number,
  phaseTwoTime: number,
  teams?: ModelTeamConnection | null,
  currentQuestionIndex?: number | null,
  currentState: GameSessionState,
  gameCode: number,
  isAdvancedMode: boolean,
  imageUrl?: string | null,
  description?: string | null,
  title?: string | null,
  currentTimer?: number | null,
  sessionData?: string | null,
  questions?: ModelQuestionConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items:  Array<Team | null >,
  nextToken?: string | null,
};

export type Team = {
  __typename: "Team",
  id: string,
  name: string,
  question?: Question | null,
  teamMembers?: ModelTeamMemberConnection | null,
  score: number,
  selectedAvatarIndex: number,
  createdAt: string,
  updatedAt: string,
  gameSessionTeamsId?: string | null,
  teamQuestionId?: string | null,
  teamQuestionOrder?: number | null,
  teamQuestionGameSessionId?: string | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  text: string,
  choices?: string | null,
  answerSettings?: string | null,
  answerData?: string | null,
  hints?: string | null,
  imageUrl?: string | null,
  instructions?: string | null,
  standard?: string | null,
  cluster?: string | null,
  domain?: string | null,
  grade?: string | null,
  order: number,
  isConfidenceEnabled: boolean,
  isShortAnswerEnabled: boolean,
  isHintEnabled: boolean,
  gameSessionId: string,
};

export type ModelTeamMemberConnection = {
  __typename: "ModelTeamMemberConnection",
  items:  Array<TeamMember | null >,
  nextToken?: string | null,
};

export type TeamMember = {
  __typename: "TeamMember",
  id: string,
  isFacilitator?: boolean | null,
  answers?: ModelTeamAnswerConnection | null,
  deviceId: string,
  createdAt: string,
  updatedAt: string,
  teamTeamMembersId?: string | null,
};

export type ModelTeamAnswerConnection = {
  __typename: "ModelTeamAnswerConnection",
  items:  Array<TeamAnswer | null >,
  nextToken?: string | null,
};

export type TeamAnswer = {
  __typename: "TeamAnswer",
  id: string,
  isCorrect?: boolean | null,
  isSubmitted: boolean,
  isShortAnswerEnabled: boolean,
  currentState: GameSessionState,
  currentQuestionIndex: number,
  questionId: string,
  teamMemberAnswersId: string,
  teamAnswersId?: string | null,
  teamName?: string | null,
  text: string,
  answer: string,
  confidenceLevel?: ConfidenceLevel | null,
  hint?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum ConfidenceLevel {
  NOT_RATED = "NOT_RATED",
  NOT_AT_ALL = "NOT_AT_ALL",
  KINDA = "KINDA",
  QUITE = "QUITE",
  VERY = "VERY",
  TOTALLY = "TOTALLY",
}


export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items:  Array<Question | null >,
  nextToken?: string | null,
};

export type UpdateGameSessionInput = {
  id: string,
  gameId?: string | null,
  startTime?: string | null,
  phaseOneTime?: number | null,
  phaseTwoTime?: number | null,
  currentQuestionIndex?: number | null,
  currentState?: GameSessionState | null,
  gameCode?: number | null,
  isAdvancedMode?: boolean | null,
  imageUrl?: string | null,
  description?: string | null,
  title?: string | null,
  currentTimer?: number | null,
  sessionData?: string | null,
};

export type DeleteGameSessionInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  text: string,
  choices?: string | null,
  answerSettings?: string | null,
  answerData?: string | null,
  hints?: string | null,
  imageUrl?: string | null,
  instructions?: string | null,
  standard?: string | null,
  cluster?: string | null,
  domain?: string | null,
  grade?: string | null,
  order: number,
  isConfidenceEnabled: boolean,
  isShortAnswerEnabled: boolean,
  isHintEnabled: boolean,
  gameSessionId: string,
};

export type ModelQuestionConditionInput = {
  text?: ModelStringInput | null,
  choices?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  answerData?: ModelStringInput | null,
  hints?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  isConfidenceEnabled?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  isHintEnabled?: ModelBooleanInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionInput = {
  id: string,
  text?: string | null,
  choices?: string | null,
  answerSettings?: string | null,
  answerData?: string | null,
  hints?: string | null,
  imageUrl?: string | null,
  instructions?: string | null,
  standard?: string | null,
  cluster?: string | null,
  domain?: string | null,
  grade?: string | null,
  order: number,
  isConfidenceEnabled?: boolean | null,
  isShortAnswerEnabled?: boolean | null,
  isHintEnabled?: boolean | null,
  gameSessionId: string,
};

export type DeleteQuestionInput = {
  id: string,
  order: number,
  gameSessionId: string,
};

export type CreateTeamInput = {
  id?: string | null,
  name: string,
  score: number,
  selectedAvatarIndex: number,
  gameSessionTeamsId?: string | null,
  teamQuestionId?: string | null,
  teamQuestionOrder?: number | null,
  teamQuestionGameSessionId?: string | null,
};

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null,
  score?: ModelIntInput | null,
  selectedAvatarIndex?: ModelIntInput | null,
  and?: Array< ModelTeamConditionInput | null > | null,
  or?: Array< ModelTeamConditionInput | null > | null,
  not?: ModelTeamConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  gameSessionTeamsId?: ModelIDInput | null,
  teamQuestionId?: ModelIDInput | null,
  teamQuestionOrder?: ModelIntInput | null,
  teamQuestionGameSessionId?: ModelIDInput | null,
};

export type UpdateTeamInput = {
  id: string,
  name?: string | null,
  score?: number | null,
  selectedAvatarIndex?: number | null,
  gameSessionTeamsId?: string | null,
  teamQuestionId?: string | null,
  teamQuestionOrder?: number | null,
  teamQuestionGameSessionId?: string | null,
};

export type DeleteTeamInput = {
  id: string,
};

export type CreateTeamMemberInput = {
  id?: string | null,
  isFacilitator?: boolean | null,
  deviceId: string,
  teamTeamMembersId?: string | null,
};

export type ModelTeamMemberConditionInput = {
  isFacilitator?: ModelBooleanInput | null,
  deviceId?: ModelIDInput | null,
  and?: Array< ModelTeamMemberConditionInput | null > | null,
  or?: Array< ModelTeamMemberConditionInput | null > | null,
  not?: ModelTeamMemberConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  teamTeamMembersId?: ModelIDInput | null,
};

export type UpdateTeamMemberInput = {
  id: string,
  isFacilitator?: boolean | null,
  deviceId?: string | null,
  teamTeamMembersId?: string | null,
};

export type DeleteTeamMemberInput = {
  id: string,
};

export type CreateTeamAnswerInput = {
  id?: string | null,
  isCorrect?: boolean | null,
  isSubmitted: boolean,
  isShortAnswerEnabled: boolean,
  currentState: GameSessionState,
  currentQuestionIndex: number,
  questionId: string,
  teamMemberAnswersId: string,
  teamAnswersId?: string | null,
  teamName?: string | null,
  text: string,
  answer: string,
  confidenceLevel?: ConfidenceLevel | null,
  hint?: string | null,
};

export type ModelTeamAnswerConditionInput = {
  isCorrect?: ModelBooleanInput | null,
  isSubmitted?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  currentState?: ModelGameSessionStateInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  questionId?: ModelIDInput | null,
  teamMemberAnswersId?: ModelIDInput | null,
  teamAnswersId?: ModelIDInput | null,
  teamName?: ModelStringInput | null,
  text?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  confidenceLevel?: ModelConfidenceLevelInput | null,
  hint?: ModelStringInput | null,
  and?: Array< ModelTeamAnswerConditionInput | null > | null,
  or?: Array< ModelTeamAnswerConditionInput | null > | null,
  not?: ModelTeamAnswerConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelConfidenceLevelInput = {
  eq?: ConfidenceLevel | null,
  ne?: ConfidenceLevel | null,
};

export type UpdateTeamAnswerInput = {
  id: string,
  isCorrect?: boolean | null,
  isSubmitted?: boolean | null,
  isShortAnswerEnabled?: boolean | null,
  currentState?: GameSessionState | null,
  currentQuestionIndex?: number | null,
  questionId?: string | null,
  teamMemberAnswersId?: string | null,
  teamAnswersId?: string | null,
  teamName?: string | null,
  text?: string | null,
  answer?: string | null,
  confidenceLevel?: ConfidenceLevel | null,
  hint?: string | null,
};

export type DeleteTeamAnswerInput = {
  id: string,
};

export type CreatePublicGameQuestionsInput = {
  id?: string | null,
  publicGameTemplateID: string,
  publicQuestionTemplateID: string,
};

export type ModelPublicGameQuestionsConditionInput = {
  publicGameTemplateID?: ModelIDInput | null,
  publicQuestionTemplateID?: ModelIDInput | null,
  and?: Array< ModelPublicGameQuestionsConditionInput | null > | null,
  or?: Array< ModelPublicGameQuestionsConditionInput | null > | null,
  not?: ModelPublicGameQuestionsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdatePublicGameQuestionsInput = {
  id: string,
  publicGameTemplateID?: string | null,
  publicQuestionTemplateID?: string | null,
};

export type DeletePublicGameQuestionsInput = {
  id: string,
};

export type CreatePrivateGameQuestionsInput = {
  id?: string | null,
  privateGameTemplateID: string,
  privateQuestionTemplateID: string,
};

export type ModelPrivateGameQuestionsConditionInput = {
  privateGameTemplateID?: ModelIDInput | null,
  privateQuestionTemplateID?: ModelIDInput | null,
  and?: Array< ModelPrivateGameQuestionsConditionInput | null > | null,
  or?: Array< ModelPrivateGameQuestionsConditionInput | null > | null,
  not?: ModelPrivateGameQuestionsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
};

export type UpdatePrivateGameQuestionsInput = {
  id: string,
  privateGameTemplateID?: string | null,
  privateQuestionTemplateID?: string | null,
};

export type DeletePrivateGameQuestionsInput = {
  id: string,
};

export type CreateGameSessionFromTemplateInput = {
  gameTemplateId: string,
  publicPrivate: string,
};

export type ModelPublicGameTemplateFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  version?: ModelIntInput | null,
  description?: ModelStringInput | null,
  ccss?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  gradeFilter?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  imageUrl?: ModelStringInput | null,
  questionTemplatesCount?: ModelIntInput | null,
  questionTemplatesOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPublicGameTemplateFilterInput | null > | null,
  or?: Array< ModelPublicGameTemplateFilterInput | null > | null,
  not?: ModelPublicGameTemplateFilterInput | null,
};

export type ModelPublicGameTemplateConnection = {
  __typename: "ModelPublicGameTemplateConnection",
  items:  Array<PublicGameTemplate | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPrivateGameTemplateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  title?: ModelStringInput | null,
  version?: ModelIntInput | null,
  description?: ModelStringInput | null,
  ccss?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  gradeFilter?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  imageUrl?: ModelStringInput | null,
  questionTemplatesCount?: ModelIntInput | null,
  questionTemplatesOrder?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPrivateGameTemplateFilterInput | null > | null,
  or?: Array< ModelPrivateGameTemplateFilterInput | null > | null,
  not?: ModelPrivateGameTemplateFilterInput | null,
};

export type ModelPrivateGameTemplateConnection = {
  __typename: "ModelPrivateGameTemplateConnection",
  items:  Array<PrivateGameTemplate | null >,
  nextToken?: string | null,
};

export type ModelPublicQuestionTemplateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  title?: ModelStringInput | null,
  version?: ModelIntInput | null,
  choices?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  gameTemplatesCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPublicQuestionTemplateFilterInput | null > | null,
  or?: Array< ModelPublicQuestionTemplateFilterInput | null > | null,
  not?: ModelPublicQuestionTemplateFilterInput | null,
};

export type ModelPublicQuestionTemplateConnection = {
  __typename: "ModelPublicQuestionTemplateConnection",
  items:  Array<PublicQuestionTemplate | null >,
  nextToken?: string | null,
};

export type ModelPrivateQuestionTemplateFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  title?: ModelStringInput | null,
  version?: ModelIntInput | null,
  choices?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  gameTemplatesCount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelPrivateQuestionTemplateFilterInput | null > | null,
  or?: Array< ModelPrivateQuestionTemplateFilterInput | null > | null,
  not?: ModelPrivateQuestionTemplateFilterInput | null,
};

export type ModelPrivateQuestionTemplateConnection = {
  __typename: "ModelPrivateQuestionTemplateConnection",
  items:  Array<PrivateQuestionTemplate | null >,
  nextToken?: string | null,
};

export type ModelGameSessionFilterInput = {
  id?: ModelIDInput | null,
  gameId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  phaseOneTime?: ModelIntInput | null,
  phaseTwoTime?: ModelIntInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  currentState?: ModelGameSessionStateInput | null,
  gameCode?: ModelIntInput | null,
  isAdvancedMode?: ModelBooleanInput | null,
  imageUrl?: ModelStringInput | null,
  description?: ModelStringInput | null,
  title?: ModelStringInput | null,
  currentTimer?: ModelIntInput | null,
  sessionData?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGameSessionFilterInput | null > | null,
  or?: Array< ModelGameSessionFilterInput | null > | null,
  not?: ModelGameSessionFilterInput | null,
};

export type ModelGameSessionConnection = {
  __typename: "ModelGameSessionConnection",
  items:  Array<GameSession | null >,
  nextToken?: string | null,
};

export type ModelQuestionPrimaryCompositeKeyConditionInput = {
  eq?: ModelQuestionPrimaryCompositeKeyInput | null,
  le?: ModelQuestionPrimaryCompositeKeyInput | null,
  lt?: ModelQuestionPrimaryCompositeKeyInput | null,
  ge?: ModelQuestionPrimaryCompositeKeyInput | null,
  gt?: ModelQuestionPrimaryCompositeKeyInput | null,
  between?: Array< ModelQuestionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelQuestionPrimaryCompositeKeyInput | null,
};

export type ModelQuestionPrimaryCompositeKeyInput = {
  order?: number | null,
  gameSessionId?: string | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  text?: ModelStringInput | null,
  choices?: ModelStringInput | null,
  answerSettings?: ModelStringInput | null,
  answerData?: ModelStringInput | null,
  hints?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  instructions?: ModelStringInput | null,
  standard?: ModelStringInput | null,
  cluster?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  grade?: ModelStringInput | null,
  order?: ModelIntInput | null,
  isConfidenceEnabled?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  isHintEnabled?: ModelBooleanInput | null,
  gameSessionId?: ModelIDInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
};

export type ModelTeamFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  score?: ModelIntInput | null,
  selectedAvatarIndex?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamFilterInput | null > | null,
  or?: Array< ModelTeamFilterInput | null > | null,
  not?: ModelTeamFilterInput | null,
  gameSessionTeamsId?: ModelIDInput | null,
  teamQuestionId?: ModelIDInput | null,
  teamQuestionOrder?: ModelIntInput | null,
  teamQuestionGameSessionId?: ModelIDInput | null,
};

export type ModelTeamMemberFilterInput = {
  id?: ModelIDInput | null,
  isFacilitator?: ModelBooleanInput | null,
  deviceId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamMemberFilterInput | null > | null,
  or?: Array< ModelTeamMemberFilterInput | null > | null,
  not?: ModelTeamMemberFilterInput | null,
  teamTeamMembersId?: ModelIDInput | null,
};

export type ModelTeamAnswerFilterInput = {
  id?: ModelIDInput | null,
  isCorrect?: ModelBooleanInput | null,
  isSubmitted?: ModelBooleanInput | null,
  isShortAnswerEnabled?: ModelBooleanInput | null,
  currentState?: ModelGameSessionStateInput | null,
  currentQuestionIndex?: ModelIntInput | null,
  questionId?: ModelIDInput | null,
  teamMemberAnswersId?: ModelIDInput | null,
  teamAnswersId?: ModelIDInput | null,
  teamName?: ModelStringInput | null,
  text?: ModelStringInput | null,
  answer?: ModelStringInput | null,
  confidenceLevel?: ModelConfidenceLevelInput | null,
  hint?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTeamAnswerFilterInput | null > | null,
  or?: Array< ModelTeamAnswerFilterInput | null > | null,
  not?: ModelTeamAnswerFilterInput | null,
};

export type ModelPublicGameQuestionsFilterInput = {
  id?: ModelIDInput | null,
  publicGameTemplateID?: ModelIDInput | null,
  publicQuestionTemplateID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPublicGameQuestionsFilterInput | null > | null,
  or?: Array< ModelPublicGameQuestionsFilterInput | null > | null,
  not?: ModelPublicGameQuestionsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPrivateGameQuestionsFilterInput = {
  id?: ModelIDInput | null,
  privateGameTemplateID?: ModelIDInput | null,
  privateQuestionTemplateID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPrivateGameQuestionsFilterInput | null > | null,
  or?: Array< ModelPrivateGameQuestionsFilterInput | null > | null,
  not?: ModelPrivateGameQuestionsFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPublicGameTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  ccss?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  cluster?: ModelSubscriptionStringInput | null,
  grade?: ModelSubscriptionStringInput | null,
  gradeFilter?: ModelSubscriptionStringInput | null,
  standard?: ModelSubscriptionStringInput | null,
  phaseOneTime?: ModelSubscriptionIntInput | null,
  phaseTwoTime?: ModelSubscriptionIntInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  questionTemplatesCount?: ModelSubscriptionIntInput | null,
  questionTemplatesOrder?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPublicGameTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionPublicGameTemplateFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionPrivateGameTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  description?: ModelSubscriptionStringInput | null,
  ccss?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  cluster?: ModelSubscriptionStringInput | null,
  grade?: ModelSubscriptionStringInput | null,
  gradeFilter?: ModelSubscriptionStringInput | null,
  standard?: ModelSubscriptionStringInput | null,
  phaseOneTime?: ModelSubscriptionIntInput | null,
  phaseTwoTime?: ModelSubscriptionIntInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  questionTemplatesCount?: ModelSubscriptionIntInput | null,
  questionTemplatesOrder?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPrivateGameTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionPrivateGameTemplateFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPublicQuestionTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  choices?: ModelSubscriptionStringInput | null,
  instructions?: ModelSubscriptionStringInput | null,
  answerSettings?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  cluster?: ModelSubscriptionStringInput | null,
  grade?: ModelSubscriptionStringInput | null,
  standard?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  gameTemplatesCount?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPublicQuestionTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionPublicQuestionTemplateFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPrivateQuestionTemplateFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionIntInput | null,
  choices?: ModelSubscriptionStringInput | null,
  instructions?: ModelSubscriptionStringInput | null,
  answerSettings?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  cluster?: ModelSubscriptionStringInput | null,
  grade?: ModelSubscriptionStringInput | null,
  standard?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  gameTemplatesCount?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPrivateQuestionTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionPrivateQuestionTemplateFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionGameSessionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  gameId?: ModelSubscriptionIDInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  phaseOneTime?: ModelSubscriptionIntInput | null,
  phaseTwoTime?: ModelSubscriptionIntInput | null,
  currentQuestionIndex?: ModelSubscriptionIntInput | null,
  currentState?: ModelSubscriptionStringInput | null,
  gameCode?: ModelSubscriptionIntInput | null,
  isAdvancedMode?: ModelSubscriptionBooleanInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  currentTimer?: ModelSubscriptionIntInput | null,
  sessionData?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGameSessionFilterInput | null > | null,
  or?: Array< ModelSubscriptionGameSessionFilterInput | null > | null,
  gameSessionTeamsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionTeamFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  score?: ModelSubscriptionIntInput | null,
  selectedAvatarIndex?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamFilterInput | null > | null,
  teamTeamMembersId?: ModelSubscriptionIDInput | null,
  teamQuestionId?: ModelSubscriptionIDInput | null,
  teamQuestionOrder?: ModelSubscriptionIntInput | null,
  teamQuestionGameSessionId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTeamMemberFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isFacilitator?: ModelSubscriptionBooleanInput | null,
  deviceId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamMemberFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamMemberFilterInput | null > | null,
  teamMemberAnswersId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionTeamAnswerFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isCorrect?: ModelSubscriptionBooleanInput | null,
  isSubmitted?: ModelSubscriptionBooleanInput | null,
  isShortAnswerEnabled?: ModelSubscriptionBooleanInput | null,
  currentState?: ModelSubscriptionStringInput | null,
  currentQuestionIndex?: ModelSubscriptionIntInput | null,
  questionId?: ModelSubscriptionIDInput | null,
  teamMemberAnswersId?: ModelSubscriptionIDInput | null,
  teamAnswersId?: ModelSubscriptionIDInput | null,
  teamName?: ModelSubscriptionStringInput | null,
  text?: ModelSubscriptionStringInput | null,
  answer?: ModelSubscriptionStringInput | null,
  confidenceLevel?: ModelSubscriptionStringInput | null,
  hint?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTeamAnswerFilterInput | null > | null,
  or?: Array< ModelSubscriptionTeamAnswerFilterInput | null > | null,
};

export type ModelSubscriptionPublicGameQuestionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  publicGameTemplateID?: ModelSubscriptionIDInput | null,
  publicQuestionTemplateID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPublicGameQuestionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionPublicGameQuestionsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionPrivateGameQuestionsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  privateGameTemplateID?: ModelSubscriptionIDInput | null,
  privateQuestionTemplateID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPrivateGameQuestionsFilterInput | null > | null,
  or?: Array< ModelSubscriptionPrivateGameQuestionsFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type CreatePublicGameTemplateMutationVariables = {
  input: CreatePublicGameTemplateInput,
  condition?: ModelPublicGameTemplateConditionInput | null,
};

export type CreatePublicGameTemplateMutation = {
  createPublicGameTemplate?:  {
    __typename: "PublicGameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type UpdatePublicGameTemplateMutationVariables = {
  input: UpdatePublicGameTemplateInput,
  condition?: ModelPublicGameTemplateConditionInput | null,
};

export type UpdatePublicGameTemplateMutation = {
  updatePublicGameTemplate?:  {
    __typename: "PublicGameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type DeletePublicGameTemplateMutationVariables = {
  input: DeletePublicGameTemplateInput,
  condition?: ModelPublicGameTemplateConditionInput | null,
};

export type DeletePublicGameTemplateMutation = {
  deletePublicGameTemplate?:  {
    __typename: "PublicGameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type CreatePrivateGameTemplateMutationVariables = {
  input: CreatePrivateGameTemplateInput,
  condition?: ModelPrivateGameTemplateConditionInput | null,
};

export type CreatePrivateGameTemplateMutation = {
  createPrivateGameTemplate?:  {
    __typename: "PrivateGameTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type UpdatePrivateGameTemplateMutationVariables = {
  input: UpdatePrivateGameTemplateInput,
  condition?: ModelPrivateGameTemplateConditionInput | null,
};

export type UpdatePrivateGameTemplateMutation = {
  updatePrivateGameTemplate?:  {
    __typename: "PrivateGameTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type DeletePrivateGameTemplateMutationVariables = {
  input: DeletePrivateGameTemplateInput,
  condition?: ModelPrivateGameTemplateConditionInput | null,
};

export type DeletePrivateGameTemplateMutation = {
  deletePrivateGameTemplate?:  {
    __typename: "PrivateGameTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type CreatePublicQuestionTemplateMutationVariables = {
  input: CreatePublicQuestionTemplateInput,
  condition?: ModelPublicQuestionTemplateConditionInput | null,
};

export type CreatePublicQuestionTemplateMutation = {
  createPublicQuestionTemplate?:  {
    __typename: "PublicQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type UpdatePublicQuestionTemplateMutationVariables = {
  input: UpdatePublicQuestionTemplateInput,
  condition?: ModelPublicQuestionTemplateConditionInput | null,
};

export type UpdatePublicQuestionTemplateMutation = {
  updatePublicQuestionTemplate?:  {
    __typename: "PublicQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type DeletePublicQuestionTemplateMutationVariables = {
  input: DeletePublicQuestionTemplateInput,
  condition?: ModelPublicQuestionTemplateConditionInput | null,
};

export type DeletePublicQuestionTemplateMutation = {
  deletePublicQuestionTemplate?:  {
    __typename: "PublicQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type CreatePrivateQuestionTemplateMutationVariables = {
  input: CreatePrivateQuestionTemplateInput,
  condition?: ModelPrivateQuestionTemplateConditionInput | null,
};

export type CreatePrivateQuestionTemplateMutation = {
  createPrivateQuestionTemplate?:  {
    __typename: "PrivateQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type UpdatePrivateQuestionTemplateMutationVariables = {
  input: UpdatePrivateQuestionTemplateInput,
  condition?: ModelPrivateQuestionTemplateConditionInput | null,
};

export type UpdatePrivateQuestionTemplateMutation = {
  updatePrivateQuestionTemplate?:  {
    __typename: "PrivateQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type DeletePrivateQuestionTemplateMutationVariables = {
  input: DeletePrivateQuestionTemplateInput,
  condition?: ModelPrivateQuestionTemplateConditionInput | null,
};

export type DeletePrivateQuestionTemplateMutation = {
  deletePrivateQuestionTemplate?:  {
    __typename: "PrivateQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type CreateGameSessionMutationVariables = {
  input: CreateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type CreateGameSessionMutation = {
  createGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGameSessionMutationVariables = {
  input: UpdateGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type UpdateGameSessionMutation = {
  updateGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGameSessionMutationVariables = {
  input: DeleteGameSessionInput,
  condition?: ModelGameSessionConditionInput | null,
};

export type DeleteGameSessionMutation = {
  deleteGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    answerData?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    answerData?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    answerData?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type CreateTeamMemberMutationVariables = {
  input: CreateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type CreateTeamMemberMutation = {
  createTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type UpdateTeamMemberMutationVariables = {
  input: UpdateTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type UpdateTeamMemberMutation = {
  updateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type DeleteTeamMemberMutationVariables = {
  input: DeleteTeamMemberInput,
  condition?: ModelTeamMemberConditionInput | null,
};

export type DeleteTeamMemberMutation = {
  deleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type CreateTeamAnswerMutationVariables = {
  input: CreateTeamAnswerInput,
  condition?: ModelTeamAnswerConditionInput | null,
};

export type CreateTeamAnswerMutation = {
  createTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTeamAnswerMutationVariables = {
  input: UpdateTeamAnswerInput,
  condition?: ModelTeamAnswerConditionInput | null,
};

export type UpdateTeamAnswerMutation = {
  updateTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTeamAnswerMutationVariables = {
  input: DeleteTeamAnswerInput,
  condition?: ModelTeamAnswerConditionInput | null,
};

export type DeleteTeamAnswerMutation = {
  deleteTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePublicGameQuestionsMutationVariables = {
  input: CreatePublicGameQuestionsInput,
  condition?: ModelPublicGameQuestionsConditionInput | null,
};

export type CreatePublicGameQuestionsMutation = {
  createPublicGameQuestions?:  {
    __typename: "PublicGameQuestions",
    id: string,
    publicGameTemplateID: string,
    publicQuestionTemplateID: string,
    publicGameTemplate:  {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    publicQuestionTemplate:  {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePublicGameQuestionsMutationVariables = {
  input: UpdatePublicGameQuestionsInput,
  condition?: ModelPublicGameQuestionsConditionInput | null,
};

export type UpdatePublicGameQuestionsMutation = {
  updatePublicGameQuestions?:  {
    __typename: "PublicGameQuestions",
    id: string,
    publicGameTemplateID: string,
    publicQuestionTemplateID: string,
    publicGameTemplate:  {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    publicQuestionTemplate:  {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePublicGameQuestionsMutationVariables = {
  input: DeletePublicGameQuestionsInput,
  condition?: ModelPublicGameQuestionsConditionInput | null,
};

export type DeletePublicGameQuestionsMutation = {
  deletePublicGameQuestions?:  {
    __typename: "PublicGameQuestions",
    id: string,
    publicGameTemplateID: string,
    publicQuestionTemplateID: string,
    publicGameTemplate:  {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    publicQuestionTemplate:  {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePrivateGameQuestionsMutationVariables = {
  input: CreatePrivateGameQuestionsInput,
  condition?: ModelPrivateGameQuestionsConditionInput | null,
};

export type CreatePrivateGameQuestionsMutation = {
  createPrivateGameQuestions?:  {
    __typename: "PrivateGameQuestions",
    id: string,
    privateGameTemplateID: string,
    privateQuestionTemplateID: string,
    privateGameTemplate:  {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    privateQuestionTemplate:  {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePrivateGameQuestionsMutationVariables = {
  input: UpdatePrivateGameQuestionsInput,
  condition?: ModelPrivateGameQuestionsConditionInput | null,
};

export type UpdatePrivateGameQuestionsMutation = {
  updatePrivateGameQuestions?:  {
    __typename: "PrivateGameQuestions",
    id: string,
    privateGameTemplateID: string,
    privateQuestionTemplateID: string,
    privateGameTemplate:  {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    privateQuestionTemplate:  {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePrivateGameQuestionsMutationVariables = {
  input: DeletePrivateGameQuestionsInput,
  condition?: ModelPrivateGameQuestionsConditionInput | null,
};

export type DeletePrivateGameQuestionsMutation = {
  deletePrivateGameQuestions?:  {
    __typename: "PrivateGameQuestions",
    id: string,
    privateGameTemplateID: string,
    privateQuestionTemplateID: string,
    privateGameTemplate:  {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    privateQuestionTemplate:  {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateGameSessionFromTemplateMutationVariables = {
  input: CreateGameSessionFromTemplateInput,
};

export type CreateGameSessionFromTemplateMutation = {
  createGameSessionFromTemplate?: string | null,
};

export type GetPublicGameTemplateQueryVariables = {
  id: string,
};

export type GetPublicGameTemplateQuery = {
  getPublicGameTemplate?:  {
    __typename: "PublicGameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type ListPublicGameTemplatesQueryVariables = {
  filter?: ModelPublicGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublicGameTemplatesQuery = {
  listPublicGameTemplates?:  {
    __typename: "ModelPublicGameTemplateConnection",
    items:  Array< {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PublicGameTemplatesByOwnerQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublicGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PublicGameTemplatesByOwnerQuery = {
  publicGameTemplatesByOwner?:  {
    __typename: "ModelPublicGameTemplateConnection",
    items:  Array< {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PublicGameTemplatesByDateQueryVariables = {
  type: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublicGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PublicGameTemplatesByDateQuery = {
  publicGameTemplatesByDate?:  {
    __typename: "ModelPublicGameTemplateConnection",
    items:  Array< {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PublicGameTemplatesByGradeQueryVariables = {
  type: string,
  grade?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublicGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PublicGameTemplatesByGradeQuery = {
  publicGameTemplatesByGrade?:  {
    __typename: "ModelPublicGameTemplateConnection",
    items:  Array< {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PublicGameTemplatesByPublicQuestionTemplatesCountQueryVariables = {
  type: string,
  questionTemplatesCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublicGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PublicGameTemplatesByPublicQuestionTemplatesCountQuery = {
  publicGameTemplatesByPublicQuestionTemplatesCount?:  {
    __typename: "ModelPublicGameTemplateConnection",
    items:  Array< {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPrivateGameTemplateQueryVariables = {
  id: string,
};

export type GetPrivateGameTemplateQuery = {
  getPrivateGameTemplate?:  {
    __typename: "PrivateGameTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type ListPrivateGameTemplatesQueryVariables = {
  filter?: ModelPrivateGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrivateGameTemplatesQuery = {
  listPrivateGameTemplates?:  {
    __typename: "ModelPrivateGameTemplateConnection",
    items:  Array< {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PrivateGameTemplatesByOwnerQueryVariables = {
  owner: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrivateGameTemplatesByOwnerQuery = {
  privateGameTemplatesByOwner?:  {
    __typename: "ModelPrivateGameTemplateConnection",
    items:  Array< {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PrivateGameTemplatesByDateQueryVariables = {
  type: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrivateGameTemplatesByDateQuery = {
  privateGameTemplatesByDate?:  {
    __typename: "ModelPrivateGameTemplateConnection",
    items:  Array< {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PrivateGameTemplatesByGradeQueryVariables = {
  type: string,
  grade?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrivateGameTemplatesByGradeQuery = {
  privateGameTemplatesByGrade?:  {
    __typename: "ModelPrivateGameTemplateConnection",
    items:  Array< {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PrivateGameTemplatesByPrivateQuestionTemplatesCountQueryVariables = {
  type: string,
  questionTemplatesCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateGameTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrivateGameTemplatesByPrivateQuestionTemplatesCountQuery = {
  privateGameTemplatesByPrivateQuestionTemplatesCount?:  {
    __typename: "ModelPrivateGameTemplateConnection",
    items:  Array< {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublicQuestionTemplateQueryVariables = {
  id: string,
};

export type GetPublicQuestionTemplateQuery = {
  getPublicQuestionTemplate?:  {
    __typename: "PublicQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type ListPublicQuestionTemplatesQueryVariables = {
  filter?: ModelPublicQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublicQuestionTemplatesQuery = {
  listPublicQuestionTemplates?:  {
    __typename: "ModelPublicQuestionTemplateConnection",
    items:  Array< {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PublicQuestionTemplatesByDateQueryVariables = {
  type: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublicQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PublicQuestionTemplatesByDateQuery = {
  publicQuestionTemplatesByDate?:  {
    __typename: "ModelPublicQuestionTemplateConnection",
    items:  Array< {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PublicQuestionTemplatesByGradeQueryVariables = {
  type: string,
  grade?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublicQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PublicQuestionTemplatesByGradeQuery = {
  publicQuestionTemplatesByGrade?:  {
    __typename: "ModelPublicQuestionTemplateConnection",
    items:  Array< {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PublicQuestionTemplatesByPublicGameTemplatesCountQueryVariables = {
  type: string,
  gameTemplatesCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPublicQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PublicQuestionTemplatesByPublicGameTemplatesCountQuery = {
  publicQuestionTemplatesByPublicGameTemplatesCount?:  {
    __typename: "ModelPublicQuestionTemplateConnection",
    items:  Array< {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPrivateQuestionTemplateQueryVariables = {
  id: string,
};

export type GetPrivateQuestionTemplateQuery = {
  getPrivateQuestionTemplate?:  {
    __typename: "PrivateQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type ListPrivateQuestionTemplatesQueryVariables = {
  filter?: ModelPrivateQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrivateQuestionTemplatesQuery = {
  listPrivateQuestionTemplates?:  {
    __typename: "ModelPrivateQuestionTemplateConnection",
    items:  Array< {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PrivateQuestionTemplatesByDateQueryVariables = {
  type: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrivateQuestionTemplatesByDateQuery = {
  privateQuestionTemplatesByDate?:  {
    __typename: "ModelPrivateQuestionTemplateConnection",
    items:  Array< {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PrivateQuestionTemplatesByGradeQueryVariables = {
  type: string,
  grade?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrivateQuestionTemplatesByGradeQuery = {
  privateQuestionTemplatesByGrade?:  {
    __typename: "ModelPrivateQuestionTemplateConnection",
    items:  Array< {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PrivateQuestionTemplatesByPrivateGameTemplatesCountQueryVariables = {
  type: string,
  gameTemplatesCount?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPrivateQuestionTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PrivateQuestionTemplatesByPrivateGameTemplatesCountQuery = {
  privateQuestionTemplatesByPrivateGameTemplatesCount?:  {
    __typename: "ModelPrivateQuestionTemplateConnection",
    items:  Array< {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGameSessionQueryVariables = {
  id: string,
};

export type GetGameSessionQuery = {
  getGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGameSessionsQueryVariables = {
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGameSessionsQuery = {
  listGameSessions?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      gameId: string,
      startTime?: string | null,
      phaseOneTime: number,
      phaseTwoTime: number,
      teams?:  {
        __typename: "ModelTeamConnection",
        items:  Array< {
          __typename: "Team",
          id: string,
          name: string,
          question?:  {
            __typename: "Question",
            id: string,
            text: string,
            choices?: string | null,
            answerSettings?: string | null,
            answerData?: string | null,
            hints?: string | null,
            imageUrl?: string | null,
            instructions?: string | null,
            standard?: string | null,
            cluster?: string | null,
            domain?: string | null,
            grade?: string | null,
            order: number,
            isConfidenceEnabled: boolean,
            isShortAnswerEnabled: boolean,
            isHintEnabled: boolean,
            gameSessionId: string,
          } | null,
          teamMembers?:  {
            __typename: "ModelTeamMemberConnection",
            items:  Array< {
              __typename: "TeamMember",
              id: string,
              isFacilitator?: boolean | null,
              answers?:  {
                __typename: "ModelTeamAnswerConnection",
                items:  Array< {
                  __typename: "TeamAnswer",
                  id: string,
                  isCorrect?: boolean | null,
                  isSubmitted: boolean,
                  isShortAnswerEnabled: boolean,
                  currentState: GameSessionState,
                  currentQuestionIndex: number,
                  questionId: string,
                  teamMemberAnswersId: string,
                  teamAnswersId?: string | null,
                  teamName?: string | null,
                  text: string,
                  answer: string,
                  confidenceLevel?: ConfidenceLevel | null,
                  hint?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              deviceId: string,
              createdAt: string,
              updatedAt: string,
              teamTeamMembersId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          score: number,
          selectedAvatarIndex: number,
          createdAt: string,
          updatedAt: string,
          gameSessionTeamsId?: string | null,
          teamQuestionId?: string | null,
          teamQuestionOrder?: number | null,
          teamQuestionGameSessionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentQuestionIndex?: number | null,
      currentState: GameSessionState,
      gameCode: number,
      isAdvancedMode: boolean,
      imageUrl?: string | null,
      description?: string | null,
      title?: string | null,
      currentTimer?: number | null,
      sessionData?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameSessionByStateQueryVariables = {
  currentState: GameSessionState,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameSessionByStateQuery = {
  gameSessionByState?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      gameId: string,
      startTime?: string | null,
      phaseOneTime: number,
      phaseTwoTime: number,
      teams?:  {
        __typename: "ModelTeamConnection",
        items:  Array< {
          __typename: "Team",
          id: string,
          name: string,
          question?:  {
            __typename: "Question",
            id: string,
            text: string,
            choices?: string | null,
            answerSettings?: string | null,
            answerData?: string | null,
            hints?: string | null,
            imageUrl?: string | null,
            instructions?: string | null,
            standard?: string | null,
            cluster?: string | null,
            domain?: string | null,
            grade?: string | null,
            order: number,
            isConfidenceEnabled: boolean,
            isShortAnswerEnabled: boolean,
            isHintEnabled: boolean,
            gameSessionId: string,
          } | null,
          teamMembers?:  {
            __typename: "ModelTeamMemberConnection",
            items:  Array< {
              __typename: "TeamMember",
              id: string,
              isFacilitator?: boolean | null,
              answers?:  {
                __typename: "ModelTeamAnswerConnection",
                items:  Array< {
                  __typename: "TeamAnswer",
                  id: string,
                  isCorrect?: boolean | null,
                  isSubmitted: boolean,
                  isShortAnswerEnabled: boolean,
                  currentState: GameSessionState,
                  currentQuestionIndex: number,
                  questionId: string,
                  teamMemberAnswersId: string,
                  teamAnswersId?: string | null,
                  teamName?: string | null,
                  text: string,
                  answer: string,
                  confidenceLevel?: ConfidenceLevel | null,
                  hint?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              deviceId: string,
              createdAt: string,
              updatedAt: string,
              teamTeamMembersId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          score: number,
          selectedAvatarIndex: number,
          createdAt: string,
          updatedAt: string,
          gameSessionTeamsId?: string | null,
          teamQuestionId?: string | null,
          teamQuestionOrder?: number | null,
          teamQuestionGameSessionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentQuestionIndex?: number | null,
      currentState: GameSessionState,
      gameCode: number,
      isAdvancedMode: boolean,
      imageUrl?: string | null,
      description?: string | null,
      title?: string | null,
      currentTimer?: number | null,
      sessionData?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GameSessionByCodeQueryVariables = {
  gameCode: number,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGameSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GameSessionByCodeQuery = {
  gameSessionByCode?:  {
    __typename: "ModelGameSessionConnection",
    items:  Array< {
      __typename: "GameSession",
      id: string,
      gameId: string,
      startTime?: string | null,
      phaseOneTime: number,
      phaseTwoTime: number,
      teams?:  {
        __typename: "ModelTeamConnection",
        items:  Array< {
          __typename: "Team",
          id: string,
          name: string,
          question?:  {
            __typename: "Question",
            id: string,
            text: string,
            choices?: string | null,
            answerSettings?: string | null,
            answerData?: string | null,
            hints?: string | null,
            imageUrl?: string | null,
            instructions?: string | null,
            standard?: string | null,
            cluster?: string | null,
            domain?: string | null,
            grade?: string | null,
            order: number,
            isConfidenceEnabled: boolean,
            isShortAnswerEnabled: boolean,
            isHintEnabled: boolean,
            gameSessionId: string,
          } | null,
          teamMembers?:  {
            __typename: "ModelTeamMemberConnection",
            items:  Array< {
              __typename: "TeamMember",
              id: string,
              isFacilitator?: boolean | null,
              answers?:  {
                __typename: "ModelTeamAnswerConnection",
                items:  Array< {
                  __typename: "TeamAnswer",
                  id: string,
                  isCorrect?: boolean | null,
                  isSubmitted: boolean,
                  isShortAnswerEnabled: boolean,
                  currentState: GameSessionState,
                  currentQuestionIndex: number,
                  questionId: string,
                  teamMemberAnswersId: string,
                  teamAnswersId?: string | null,
                  teamName?: string | null,
                  text: string,
                  answer: string,
                  confidenceLevel?: ConfidenceLevel | null,
                  hint?: string | null,
                  createdAt: string,
                  updatedAt: string,
                } | null >,
                nextToken?: string | null,
              } | null,
              deviceId: string,
              createdAt: string,
              updatedAt: string,
              teamTeamMembersId?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          score: number,
          selectedAvatarIndex: number,
          createdAt: string,
          updatedAt: string,
          gameSessionTeamsId?: string | null,
          teamQuestionId?: string | null,
          teamQuestionOrder?: number | null,
          teamQuestionGameSessionId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      currentQuestionIndex?: number | null,
      currentState: GameSessionState,
      gameCode: number,
      isAdvancedMode: boolean,
      imageUrl?: string | null,
      description?: string | null,
      title?: string | null,
      currentTimer?: number | null,
      sessionData?: string | null,
      questions?:  {
        __typename: "ModelQuestionConnection",
        items:  Array< {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id: string,
  order: number,
  gameSessionId: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    text: string,
    choices?: string | null,
    answerSettings?: string | null,
    answerData?: string | null,
    hints?: string | null,
    imageUrl?: string | null,
    instructions?: string | null,
    standard?: string | null,
    cluster?: string | null,
    domain?: string | null,
    grade?: string | null,
    order: number,
    isConfidenceEnabled: boolean,
    isShortAnswerEnabled: boolean,
    isHintEnabled: boolean,
    gameSessionId: string,
  } | null,
};

export type ListQuestionsQueryVariables = {
  id?: string | null,
  orderGameSessionId?: ModelQuestionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type ListTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsQuery = {
  listTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      question?:  {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null,
      teamMembers?:  {
        __typename: "ModelTeamMemberConnection",
        items:  Array< {
          __typename: "TeamMember",
          id: string,
          isFacilitator?: boolean | null,
          answers?:  {
            __typename: "ModelTeamAnswerConnection",
            items:  Array< {
              __typename: "TeamAnswer",
              id: string,
              isCorrect?: boolean | null,
              isSubmitted: boolean,
              isShortAnswerEnabled: boolean,
              currentState: GameSessionState,
              currentQuestionIndex: number,
              questionId: string,
              teamMemberAnswersId: string,
              teamAnswersId?: string | null,
              teamName?: string | null,
              text: string,
              answer: string,
              confidenceLevel?: ConfidenceLevel | null,
              hint?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null >,
            nextToken?: string | null,
          } | null,
          deviceId: string,
          createdAt: string,
          updatedAt: string,
          teamTeamMembersId?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      score: number,
      selectedAvatarIndex: number,
      createdAt: string,
      updatedAt: string,
      gameSessionTeamsId?: string | null,
      teamQuestionId?: string | null,
      teamQuestionOrder?: number | null,
      teamQuestionGameSessionId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamMemberQueryVariables = {
  id: string,
};

export type GetTeamMemberQuery = {
  getTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type ListTeamMembersQueryVariables = {
  filter?: ModelTeamMemberFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMembersQuery = {
  listTeamMembers?:  {
    __typename: "ModelTeamMemberConnection",
    items:  Array< {
      __typename: "TeamMember",
      id: string,
      isFacilitator?: boolean | null,
      answers?:  {
        __typename: "ModelTeamAnswerConnection",
        items:  Array< {
          __typename: "TeamAnswer",
          id: string,
          isCorrect?: boolean | null,
          isSubmitted: boolean,
          isShortAnswerEnabled: boolean,
          currentState: GameSessionState,
          currentQuestionIndex: number,
          questionId: string,
          teamMemberAnswersId: string,
          teamAnswersId?: string | null,
          teamName?: string | null,
          text: string,
          answer: string,
          confidenceLevel?: ConfidenceLevel | null,
          hint?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      deviceId: string,
      createdAt: string,
      updatedAt: string,
      teamTeamMembersId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTeamAnswerQueryVariables = {
  id: string,
};

export type GetTeamAnswerQuery = {
  getTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTeamAnswersQueryVariables = {
  filter?: ModelTeamAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamAnswersQuery = {
  listTeamAnswers?:  {
    __typename: "ModelTeamAnswerConnection",
    items:  Array< {
      __typename: "TeamAnswer",
      id: string,
      isCorrect?: boolean | null,
      isSubmitted: boolean,
      isShortAnswerEnabled: boolean,
      currentState: GameSessionState,
      currentQuestionIndex: number,
      questionId: string,
      teamMemberAnswersId: string,
      teamAnswersId?: string | null,
      teamName?: string | null,
      text: string,
      answer: string,
      confidenceLevel?: ConfidenceLevel | null,
      hint?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPublicGameQuestionsQueryVariables = {
  id: string,
};

export type GetPublicGameQuestionsQuery = {
  getPublicGameQuestions?:  {
    __typename: "PublicGameQuestions",
    id: string,
    publicGameTemplateID: string,
    publicQuestionTemplateID: string,
    publicGameTemplate:  {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    publicQuestionTemplate:  {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPublicGameQuestionsQueryVariables = {
  filter?: ModelPublicGameQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPublicGameQuestionsQuery = {
  listPublicGameQuestions?:  {
    __typename: "ModelPublicGameQuestionsConnection",
    items:  Array< {
      __typename: "PublicGameQuestions",
      id: string,
      publicGameTemplateID: string,
      publicQuestionTemplateID: string,
      publicGameTemplate:  {
        __typename: "PublicGameTemplate",
        id: string,
        title: string,
        owner: string,
        version: number,
        description: string,
        ccss?: string | null,
        domain?: string | null,
        cluster?: string | null,
        grade?: string | null,
        gradeFilter?: string | null,
        standard?: string | null,
        phaseOneTime?: number | null,
        phaseTwoTime?: number | null,
        imageUrl?: string | null,
        questionTemplates?:  {
          __typename: "ModelPublicGameQuestionsConnection",
          items:  Array< {
            __typename: "PublicGameQuestions",
            id: string,
            publicGameTemplateID: string,
            publicQuestionTemplateID: string,
            publicGameTemplate:  {
              __typename: "PublicGameTemplate",
              id: string,
              title: string,
              owner: string,
              version: number,
              description: string,
              ccss?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              gradeFilter?: string | null,
              standard?: string | null,
              phaseOneTime?: number | null,
              phaseTwoTime?: number | null,
              imageUrl?: string | null,
              questionTemplates?:  {
                __typename: "ModelPublicGameQuestionsConnection",
                items:  Array< {
                  __typename: "PublicGameQuestions",
                  id: string,
                  publicGameTemplateID: string,
                  publicQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              questionTemplatesCount: number,
              questionTemplatesOrder?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            publicQuestionTemplate:  {
              __typename: "PublicQuestionTemplate",
              id: string,
              owner: string,
              title: string,
              version: number,
              choices?: string | null,
              instructions?: string | null,
              answerSettings?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              imageUrl?: string | null,
              gameTemplates?:  {
                __typename: "ModelPublicGameQuestionsConnection",
                items:  Array< {
                  __typename: "PublicGameQuestions",
                  id: string,
                  publicGameTemplateID: string,
                  publicQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              gameTemplatesCount: number,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        questionTemplatesCount: number,
        questionTemplatesOrder?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        type?: string | null,
      },
      publicQuestionTemplate:  {
        __typename: "PublicQuestionTemplate",
        id: string,
        owner: string,
        title: string,
        version: number,
        choices?: string | null,
        instructions?: string | null,
        answerSettings?: string | null,
        domain?: string | null,
        cluster?: string | null,
        grade?: string | null,
        standard?: string | null,
        imageUrl?: string | null,
        gameTemplates?:  {
          __typename: "ModelPublicGameQuestionsConnection",
          items:  Array< {
            __typename: "PublicGameQuestions",
            id: string,
            publicGameTemplateID: string,
            publicQuestionTemplateID: string,
            publicGameTemplate:  {
              __typename: "PublicGameTemplate",
              id: string,
              title: string,
              owner: string,
              version: number,
              description: string,
              ccss?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              gradeFilter?: string | null,
              standard?: string | null,
              phaseOneTime?: number | null,
              phaseTwoTime?: number | null,
              imageUrl?: string | null,
              questionTemplates?:  {
                __typename: "ModelPublicGameQuestionsConnection",
                items:  Array< {
                  __typename: "PublicGameQuestions",
                  id: string,
                  publicGameTemplateID: string,
                  publicQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              questionTemplatesCount: number,
              questionTemplatesOrder?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            publicQuestionTemplate:  {
              __typename: "PublicQuestionTemplate",
              id: string,
              owner: string,
              title: string,
              version: number,
              choices?: string | null,
              instructions?: string | null,
              answerSettings?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              imageUrl?: string | null,
              gameTemplates?:  {
                __typename: "ModelPublicGameQuestionsConnection",
                items:  Array< {
                  __typename: "PublicGameQuestions",
                  id: string,
                  publicGameTemplateID: string,
                  publicQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              gameTemplatesCount: number,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gameTemplatesCount: number,
        createdAt?: string | null,
        updatedAt?: string | null,
        type?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPrivateGameQuestionsQueryVariables = {
  id: string,
};

export type GetPrivateGameQuestionsQuery = {
  getPrivateGameQuestions?:  {
    __typename: "PrivateGameQuestions",
    id: string,
    privateGameTemplateID: string,
    privateQuestionTemplateID: string,
    privateGameTemplate:  {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    privateQuestionTemplate:  {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPrivateGameQuestionsQueryVariables = {
  filter?: ModelPrivateGameQuestionsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPrivateGameQuestionsQuery = {
  listPrivateGameQuestions?:  {
    __typename: "ModelPrivateGameQuestionsConnection",
    items:  Array< {
      __typename: "PrivateGameQuestions",
      id: string,
      privateGameTemplateID: string,
      privateQuestionTemplateID: string,
      privateGameTemplate:  {
        __typename: "PrivateGameTemplate",
        id: string,
        owner: string,
        title: string,
        version: number,
        description: string,
        ccss?: string | null,
        domain?: string | null,
        cluster?: string | null,
        grade?: string | null,
        gradeFilter?: string | null,
        standard?: string | null,
        phaseOneTime?: number | null,
        phaseTwoTime?: number | null,
        imageUrl?: string | null,
        questionTemplates?:  {
          __typename: "ModelPrivateGameQuestionsConnection",
          items:  Array< {
            __typename: "PrivateGameQuestions",
            id: string,
            privateGameTemplateID: string,
            privateQuestionTemplateID: string,
            privateGameTemplate:  {
              __typename: "PrivateGameTemplate",
              id: string,
              owner: string,
              title: string,
              version: number,
              description: string,
              ccss?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              gradeFilter?: string | null,
              standard?: string | null,
              phaseOneTime?: number | null,
              phaseTwoTime?: number | null,
              imageUrl?: string | null,
              questionTemplates?:  {
                __typename: "ModelPrivateGameQuestionsConnection",
                items:  Array< {
                  __typename: "PrivateGameQuestions",
                  id: string,
                  privateGameTemplateID: string,
                  privateQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              questionTemplatesCount: number,
              questionTemplatesOrder?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            privateQuestionTemplate:  {
              __typename: "PrivateQuestionTemplate",
              id: string,
              owner: string,
              title: string,
              version: number,
              choices?: string | null,
              instructions?: string | null,
              answerSettings?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              imageUrl?: string | null,
              gameTemplates?:  {
                __typename: "ModelPrivateGameQuestionsConnection",
                items:  Array< {
                  __typename: "PrivateGameQuestions",
                  id: string,
                  privateGameTemplateID: string,
                  privateQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              gameTemplatesCount: number,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        questionTemplatesCount: number,
        questionTemplatesOrder?: string | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        type?: string | null,
      },
      privateQuestionTemplate:  {
        __typename: "PrivateQuestionTemplate",
        id: string,
        owner: string,
        title: string,
        version: number,
        choices?: string | null,
        instructions?: string | null,
        answerSettings?: string | null,
        domain?: string | null,
        cluster?: string | null,
        grade?: string | null,
        standard?: string | null,
        imageUrl?: string | null,
        gameTemplates?:  {
          __typename: "ModelPrivateGameQuestionsConnection",
          items:  Array< {
            __typename: "PrivateGameQuestions",
            id: string,
            privateGameTemplateID: string,
            privateQuestionTemplateID: string,
            privateGameTemplate:  {
              __typename: "PrivateGameTemplate",
              id: string,
              owner: string,
              title: string,
              version: number,
              description: string,
              ccss?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              gradeFilter?: string | null,
              standard?: string | null,
              phaseOneTime?: number | null,
              phaseTwoTime?: number | null,
              imageUrl?: string | null,
              questionTemplates?:  {
                __typename: "ModelPrivateGameQuestionsConnection",
                items:  Array< {
                  __typename: "PrivateGameQuestions",
                  id: string,
                  privateGameTemplateID: string,
                  privateQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              questionTemplatesCount: number,
              questionTemplatesOrder?: string | null,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            privateQuestionTemplate:  {
              __typename: "PrivateQuestionTemplate",
              id: string,
              owner: string,
              title: string,
              version: number,
              choices?: string | null,
              instructions?: string | null,
              answerSettings?: string | null,
              domain?: string | null,
              cluster?: string | null,
              grade?: string | null,
              standard?: string | null,
              imageUrl?: string | null,
              gameTemplates?:  {
                __typename: "ModelPrivateGameQuestionsConnection",
                items:  Array< {
                  __typename: "PrivateGameQuestions",
                  id: string,
                  privateGameTemplateID: string,
                  privateQuestionTemplateID: string,
                  createdAt: string,
                  updatedAt: string,
                  owner?: string | null,
                } | null >,
                nextToken?: string | null,
              } | null,
              gameTemplatesCount: number,
              createdAt?: string | null,
              updatedAt?: string | null,
              type?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        gameTemplatesCount: number,
        createdAt?: string | null,
        updatedAt?: string | null,
        type?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreatePublicGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPublicGameTemplateFilterInput | null,
  owner?: string | null,
};

export type OnCreatePublicGameTemplateSubscription = {
  onCreatePublicGameTemplate?:  {
    __typename: "PublicGameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnUpdatePublicGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPublicGameTemplateFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePublicGameTemplateSubscription = {
  onUpdatePublicGameTemplate?:  {
    __typename: "PublicGameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnDeletePublicGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPublicGameTemplateFilterInput | null,
  owner?: string | null,
};

export type OnDeletePublicGameTemplateSubscription = {
  onDeletePublicGameTemplate?:  {
    __typename: "PublicGameTemplate",
    id: string,
    title: string,
    owner: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnCreatePrivateGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateGameTemplateFilterInput | null,
  owner?: string | null,
};

export type OnCreatePrivateGameTemplateSubscription = {
  onCreatePrivateGameTemplate?:  {
    __typename: "PrivateGameTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnUpdatePrivateGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateGameTemplateFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePrivateGameTemplateSubscription = {
  onUpdatePrivateGameTemplate?:  {
    __typename: "PrivateGameTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnDeletePrivateGameTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateGameTemplateFilterInput | null,
  owner?: string | null,
};

export type OnDeletePrivateGameTemplateSubscription = {
  onDeletePrivateGameTemplate?:  {
    __typename: "PrivateGameTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    description: string,
    ccss?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    gradeFilter?: string | null,
    standard?: string | null,
    phaseOneTime?: number | null,
    phaseTwoTime?: number | null,
    imageUrl?: string | null,
    questionTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    questionTemplatesCount: number,
    questionTemplatesOrder?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnCreatePublicQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPublicQuestionTemplateFilterInput | null,
  owner?: string | null,
};

export type OnCreatePublicQuestionTemplateSubscription = {
  onCreatePublicQuestionTemplate?:  {
    __typename: "PublicQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnUpdatePublicQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPublicQuestionTemplateFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePublicQuestionTemplateSubscription = {
  onUpdatePublicQuestionTemplate?:  {
    __typename: "PublicQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnDeletePublicQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPublicQuestionTemplateFilterInput | null,
  owner?: string | null,
};

export type OnDeletePublicQuestionTemplateSubscription = {
  onDeletePublicQuestionTemplate?:  {
    __typename: "PublicQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPublicGameQuestionsConnection",
      items:  Array< {
        __typename: "PublicGameQuestions",
        id: string,
        publicGameTemplateID: string,
        publicQuestionTemplateID: string,
        publicGameTemplate:  {
          __typename: "PublicGameTemplate",
          id: string,
          title: string,
          owner: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        publicQuestionTemplate:  {
          __typename: "PublicQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPublicGameQuestionsConnection",
            items:  Array< {
              __typename: "PublicGameQuestions",
              id: string,
              publicGameTemplateID: string,
              publicQuestionTemplateID: string,
              publicGameTemplate:  {
                __typename: "PublicGameTemplate",
                id: string,
                title: string,
                owner: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              publicQuestionTemplate:  {
                __typename: "PublicQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPublicGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnCreatePrivateQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateQuestionTemplateFilterInput | null,
  owner?: string | null,
};

export type OnCreatePrivateQuestionTemplateSubscription = {
  onCreatePrivateQuestionTemplate?:  {
    __typename: "PrivateQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnUpdatePrivateQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateQuestionTemplateFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePrivateQuestionTemplateSubscription = {
  onUpdatePrivateQuestionTemplate?:  {
    __typename: "PrivateQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnDeletePrivateQuestionTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateQuestionTemplateFilterInput | null,
  owner?: string | null,
};

export type OnDeletePrivateQuestionTemplateSubscription = {
  onDeletePrivateQuestionTemplate?:  {
    __typename: "PrivateQuestionTemplate",
    id: string,
    owner: string,
    title: string,
    version: number,
    choices?: string | null,
    instructions?: string | null,
    answerSettings?: string | null,
    domain?: string | null,
    cluster?: string | null,
    grade?: string | null,
    standard?: string | null,
    imageUrl?: string | null,
    gameTemplates?:  {
      __typename: "ModelPrivateGameQuestionsConnection",
      items:  Array< {
        __typename: "PrivateGameQuestions",
        id: string,
        privateGameTemplateID: string,
        privateQuestionTemplateID: string,
        privateGameTemplate:  {
          __typename: "PrivateGameTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          description: string,
          ccss?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          gradeFilter?: string | null,
          standard?: string | null,
          phaseOneTime?: number | null,
          phaseTwoTime?: number | null,
          imageUrl?: string | null,
          questionTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          questionTemplatesCount: number,
          questionTemplatesOrder?: string | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        privateQuestionTemplate:  {
          __typename: "PrivateQuestionTemplate",
          id: string,
          owner: string,
          title: string,
          version: number,
          choices?: string | null,
          instructions?: string | null,
          answerSettings?: string | null,
          domain?: string | null,
          cluster?: string | null,
          grade?: string | null,
          standard?: string | null,
          imageUrl?: string | null,
          gameTemplates?:  {
            __typename: "ModelPrivateGameQuestionsConnection",
            items:  Array< {
              __typename: "PrivateGameQuestions",
              id: string,
              privateGameTemplateID: string,
              privateQuestionTemplateID: string,
              privateGameTemplate:  {
                __typename: "PrivateGameTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                description: string,
                ccss?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                gradeFilter?: string | null,
                standard?: string | null,
                phaseOneTime?: number | null,
                phaseTwoTime?: number | null,
                imageUrl?: string | null,
                questionTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                questionTemplatesCount: number,
                questionTemplatesOrder?: string | null,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              privateQuestionTemplate:  {
                __typename: "PrivateQuestionTemplate",
                id: string,
                owner: string,
                title: string,
                version: number,
                choices?: string | null,
                instructions?: string | null,
                answerSettings?: string | null,
                domain?: string | null,
                cluster?: string | null,
                grade?: string | null,
                standard?: string | null,
                imageUrl?: string | null,
                gameTemplates?:  {
                  __typename: "ModelPrivateGameQuestionsConnection",
                  nextToken?: string | null,
                } | null,
                gameTemplatesCount: number,
                createdAt?: string | null,
                updatedAt?: string | null,
                type?: string | null,
              },
              createdAt: string,
              updatedAt: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          gameTemplatesCount: number,
          createdAt?: string | null,
          updatedAt?: string | null,
          type?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    gameTemplatesCount: number,
    createdAt?: string | null,
    updatedAt?: string | null,
    type?: string | null,
  } | null,
};

export type OnCreateGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
};

export type OnCreateGameSessionSubscription = {
  onCreateGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
};

export type OnUpdateGameSessionSubscription = {
  onUpdateGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGameSessionSubscriptionVariables = {
  filter?: ModelSubscriptionGameSessionFilterInput | null,
};

export type OnDeleteGameSessionSubscription = {
  onDeleteGameSession?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnCreateTeamSubscription = {
  onCreateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnUpdateTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnUpdateTeamSubscription = {
  onUpdateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnDeleteTeamSubscriptionVariables = {
  filter?: ModelSubscriptionTeamFilterInput | null,
};

export type OnDeleteTeamSubscription = {
  onDeleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnCreateTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnCreateTeamMemberSubscription = {
  onCreateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnUpdateTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnUpdateTeamMemberSubscription = {
  onUpdateTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnDeleteTeamMemberSubscriptionVariables = {
  filter?: ModelSubscriptionTeamMemberFilterInput | null,
};

export type OnDeleteTeamMemberSubscription = {
  onDeleteTeamMember?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnCreateTeamAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionTeamAnswerFilterInput | null,
};

export type OnCreateTeamAnswerSubscription = {
  onCreateTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTeamAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionTeamAnswerFilterInput | null,
};

export type OnUpdateTeamAnswerSubscription = {
  onUpdateTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTeamAnswerSubscriptionVariables = {
  filter?: ModelSubscriptionTeamAnswerFilterInput | null,
};

export type OnDeleteTeamAnswerSubscription = {
  onDeleteTeamAnswer?:  {
    __typename: "TeamAnswer",
    id: string,
    isCorrect?: boolean | null,
    isSubmitted: boolean,
    isShortAnswerEnabled: boolean,
    currentState: GameSessionState,
    currentQuestionIndex: number,
    questionId: string,
    teamMemberAnswersId: string,
    teamAnswersId?: string | null,
    teamName?: string | null,
    text: string,
    answer: string,
    confidenceLevel?: ConfidenceLevel | null,
    hint?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePublicGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionPublicGameQuestionsFilterInput | null,
  owner?: string | null,
};

export type OnCreatePublicGameQuestionsSubscription = {
  onCreatePublicGameQuestions?:  {
    __typename: "PublicGameQuestions",
    id: string,
    publicGameTemplateID: string,
    publicQuestionTemplateID: string,
    publicGameTemplate:  {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    publicQuestionTemplate:  {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePublicGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionPublicGameQuestionsFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePublicGameQuestionsSubscription = {
  onUpdatePublicGameQuestions?:  {
    __typename: "PublicGameQuestions",
    id: string,
    publicGameTemplateID: string,
    publicQuestionTemplateID: string,
    publicGameTemplate:  {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    publicQuestionTemplate:  {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePublicGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionPublicGameQuestionsFilterInput | null,
  owner?: string | null,
};

export type OnDeletePublicGameQuestionsSubscription = {
  onDeletePublicGameQuestions?:  {
    __typename: "PublicGameQuestions",
    id: string,
    publicGameTemplateID: string,
    publicQuestionTemplateID: string,
    publicGameTemplate:  {
      __typename: "PublicGameTemplate",
      id: string,
      title: string,
      owner: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    publicQuestionTemplate:  {
      __typename: "PublicQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPublicGameQuestionsConnection",
        items:  Array< {
          __typename: "PublicGameQuestions",
          id: string,
          publicGameTemplateID: string,
          publicQuestionTemplateID: string,
          publicGameTemplate:  {
            __typename: "PublicGameTemplate",
            id: string,
            title: string,
            owner: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          publicQuestionTemplate:  {
            __typename: "PublicQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPublicGameQuestionsConnection",
              items:  Array< {
                __typename: "PublicGameQuestions",
                id: string,
                publicGameTemplateID: string,
                publicQuestionTemplateID: string,
                publicGameTemplate:  {
                  __typename: "PublicGameTemplate",
                  id: string,
                  title: string,
                  owner: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                publicQuestionTemplate:  {
                  __typename: "PublicQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePrivateGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateGameQuestionsFilterInput | null,
  owner?: string | null,
};

export type OnCreatePrivateGameQuestionsSubscription = {
  onCreatePrivateGameQuestions?:  {
    __typename: "PrivateGameQuestions",
    id: string,
    privateGameTemplateID: string,
    privateQuestionTemplateID: string,
    privateGameTemplate:  {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    privateQuestionTemplate:  {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePrivateGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateGameQuestionsFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePrivateGameQuestionsSubscription = {
  onUpdatePrivateGameQuestions?:  {
    __typename: "PrivateGameQuestions",
    id: string,
    privateGameTemplateID: string,
    privateQuestionTemplateID: string,
    privateGameTemplate:  {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    privateQuestionTemplate:  {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePrivateGameQuestionsSubscriptionVariables = {
  filter?: ModelSubscriptionPrivateGameQuestionsFilterInput | null,
  owner?: string | null,
};

export type OnDeletePrivateGameQuestionsSubscription = {
  onDeletePrivateGameQuestions?:  {
    __typename: "PrivateGameQuestions",
    id: string,
    privateGameTemplateID: string,
    privateQuestionTemplateID: string,
    privateGameTemplate:  {
      __typename: "PrivateGameTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      description: string,
      ccss?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      gradeFilter?: string | null,
      standard?: string | null,
      phaseOneTime?: number | null,
      phaseTwoTime?: number | null,
      imageUrl?: string | null,
      questionTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      questionTemplatesCount: number,
      questionTemplatesOrder?: string | null,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    privateQuestionTemplate:  {
      __typename: "PrivateQuestionTemplate",
      id: string,
      owner: string,
      title: string,
      version: number,
      choices?: string | null,
      instructions?: string | null,
      answerSettings?: string | null,
      domain?: string | null,
      cluster?: string | null,
      grade?: string | null,
      standard?: string | null,
      imageUrl?: string | null,
      gameTemplates?:  {
        __typename: "ModelPrivateGameQuestionsConnection",
        items:  Array< {
          __typename: "PrivateGameQuestions",
          id: string,
          privateGameTemplateID: string,
          privateQuestionTemplateID: string,
          privateGameTemplate:  {
            __typename: "PrivateGameTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            description: string,
            ccss?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            gradeFilter?: string | null,
            standard?: string | null,
            phaseOneTime?: number | null,
            phaseTwoTime?: number | null,
            imageUrl?: string | null,
            questionTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            questionTemplatesCount: number,
            questionTemplatesOrder?: string | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          privateQuestionTemplate:  {
            __typename: "PrivateQuestionTemplate",
            id: string,
            owner: string,
            title: string,
            version: number,
            choices?: string | null,
            instructions?: string | null,
            answerSettings?: string | null,
            domain?: string | null,
            cluster?: string | null,
            grade?: string | null,
            standard?: string | null,
            imageUrl?: string | null,
            gameTemplates?:  {
              __typename: "ModelPrivateGameQuestionsConnection",
              items:  Array< {
                __typename: "PrivateGameQuestions",
                id: string,
                privateGameTemplateID: string,
                privateQuestionTemplateID: string,
                privateGameTemplate:  {
                  __typename: "PrivateGameTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  description: string,
                  ccss?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  gradeFilter?: string | null,
                  standard?: string | null,
                  phaseOneTime?: number | null,
                  phaseTwoTime?: number | null,
                  imageUrl?: string | null,
                  questionTemplatesCount: number,
                  questionTemplatesOrder?: string | null,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                privateQuestionTemplate:  {
                  __typename: "PrivateQuestionTemplate",
                  id: string,
                  owner: string,
                  title: string,
                  version: number,
                  choices?: string | null,
                  instructions?: string | null,
                  answerSettings?: string | null,
                  domain?: string | null,
                  cluster?: string | null,
                  grade?: string | null,
                  standard?: string | null,
                  imageUrl?: string | null,
                  gameTemplatesCount: number,
                  createdAt?: string | null,
                  updatedAt?: string | null,
                  type?: string | null,
                },
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            gameTemplatesCount: number,
            createdAt?: string | null,
            updatedAt?: string | null,
            type?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      gameTemplatesCount: number,
      createdAt?: string | null,
      updatedAt?: string | null,
      type?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnGameSessionUpdatedByIdSubscriptionVariables = {
  id: string,
};

export type OnGameSessionUpdatedByIdSubscription = {
  onGameSessionUpdatedById?:  {
    __typename: "GameSession",
    id: string,
    gameId: string,
    startTime?: string | null,
    phaseOneTime: number,
    phaseTwoTime: number,
    teams?:  {
      __typename: "ModelTeamConnection",
      items:  Array< {
        __typename: "Team",
        id: string,
        name: string,
        question?:  {
          __typename: "Question",
          id: string,
          text: string,
          choices?: string | null,
          answerSettings?: string | null,
          answerData?: string | null,
          hints?: string | null,
          imageUrl?: string | null,
          instructions?: string | null,
          standard?: string | null,
          cluster?: string | null,
          domain?: string | null,
          grade?: string | null,
          order: number,
          isConfidenceEnabled: boolean,
          isShortAnswerEnabled: boolean,
          isHintEnabled: boolean,
          gameSessionId: string,
        } | null,
        teamMembers?:  {
          __typename: "ModelTeamMemberConnection",
          items:  Array< {
            __typename: "TeamMember",
            id: string,
            isFacilitator?: boolean | null,
            answers?:  {
              __typename: "ModelTeamAnswerConnection",
              items:  Array< {
                __typename: "TeamAnswer",
                id: string,
                isCorrect?: boolean | null,
                isSubmitted: boolean,
                isShortAnswerEnabled: boolean,
                currentState: GameSessionState,
                currentQuestionIndex: number,
                questionId: string,
                teamMemberAnswersId: string,
                teamAnswersId?: string | null,
                teamName?: string | null,
                text: string,
                answer: string,
                confidenceLevel?: ConfidenceLevel | null,
                hint?: string | null,
                createdAt: string,
                updatedAt: string,
              } | null >,
              nextToken?: string | null,
            } | null,
            deviceId: string,
            createdAt: string,
            updatedAt: string,
            teamTeamMembersId?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        score: number,
        selectedAvatarIndex: number,
        createdAt: string,
        updatedAt: string,
        gameSessionTeamsId?: string | null,
        teamQuestionId?: string | null,
        teamQuestionOrder?: number | null,
        teamQuestionGameSessionId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    currentQuestionIndex?: number | null,
    currentState: GameSessionState,
    gameCode: number,
    isAdvancedMode: boolean,
    imageUrl?: string | null,
    description?: string | null,
    title?: string | null,
    currentTimer?: number | null,
    sessionData?: string | null,
    questions?:  {
      __typename: "ModelQuestionConnection",
      items:  Array< {
        __typename: "Question",
        id: string,
        text: string,
        choices?: string | null,
        answerSettings?: string | null,
        answerData?: string | null,
        hints?: string | null,
        imageUrl?: string | null,
        instructions?: string | null,
        standard?: string | null,
        cluster?: string | null,
        domain?: string | null,
        grade?: string | null,
        order: number,
        isConfidenceEnabled: boolean,
        isShortAnswerEnabled: boolean,
        isHintEnabled: boolean,
        gameSessionId: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnTeamMemberUpdateByTeamIdSubscriptionVariables = {
  teamTeamMembersId: string,
};

export type OnTeamMemberUpdateByTeamIdSubscription = {
  onTeamMemberUpdateByTeamId?:  {
    __typename: "TeamMember",
    id: string,
    isFacilitator?: boolean | null,
    answers?:  {
      __typename: "ModelTeamAnswerConnection",
      items:  Array< {
        __typename: "TeamAnswer",
        id: string,
        isCorrect?: boolean | null,
        isSubmitted: boolean,
        isShortAnswerEnabled: boolean,
        currentState: GameSessionState,
        currentQuestionIndex: number,
        questionId: string,
        teamMemberAnswersId: string,
        teamAnswersId?: string | null,
        teamName?: string | null,
        text: string,
        answer: string,
        confidenceLevel?: ConfidenceLevel | null,
        hint?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    teamTeamMembersId?: string | null,
  } | null,
};

export type OnTeamCreateByGameSessionIdSubscriptionVariables = {
  gameSessionTeamsId: string,
};

export type OnTeamCreateByGameSessionIdSubscription = {
  onTeamCreateByGameSessionId?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnTeamDeleteByGameSessionIdSubscriptionVariables = {
  gameSessionTeamsId: string,
};

export type OnTeamDeleteByGameSessionIdSubscription = {
  onTeamDeleteByGameSessionId?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};

export type OnTeamUpdateByGameSessionIdSubscriptionVariables = {
  gameSessionTeamsId: string,
};

export type OnTeamUpdateByGameSessionIdSubscription = {
  onTeamUpdateByGameSessionId?:  {
    __typename: "Team",
    id: string,
    name: string,
    question?:  {
      __typename: "Question",
      id: string,
      text: string,
      choices?: string | null,
      answerSettings?: string | null,
      answerData?: string | null,
      hints?: string | null,
      imageUrl?: string | null,
      instructions?: string | null,
      standard?: string | null,
      cluster?: string | null,
      domain?: string | null,
      grade?: string | null,
      order: number,
      isConfidenceEnabled: boolean,
      isShortAnswerEnabled: boolean,
      isHintEnabled: boolean,
      gameSessionId: string,
    } | null,
    teamMembers?:  {
      __typename: "ModelTeamMemberConnection",
      items:  Array< {
        __typename: "TeamMember",
        id: string,
        isFacilitator?: boolean | null,
        answers?:  {
          __typename: "ModelTeamAnswerConnection",
          items:  Array< {
            __typename: "TeamAnswer",
            id: string,
            isCorrect?: boolean | null,
            isSubmitted: boolean,
            isShortAnswerEnabled: boolean,
            currentState: GameSessionState,
            currentQuestionIndex: number,
            questionId: string,
            teamMemberAnswersId: string,
            teamAnswersId?: string | null,
            teamName?: string | null,
            text: string,
            answer: string,
            confidenceLevel?: ConfidenceLevel | null,
            hint?: string | null,
            createdAt: string,
            updatedAt: string,
          } | null >,
          nextToken?: string | null,
        } | null,
        deviceId: string,
        createdAt: string,
        updatedAt: string,
        teamTeamMembersId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    score: number,
    selectedAvatarIndex: number,
    createdAt: string,
    updatedAt: string,
    gameSessionTeamsId?: string | null,
    teamQuestionId?: string | null,
    teamQuestionOrder?: number | null,
    teamQuestionGameSessionId?: string | null,
  } | null,
};
